.video-statistics-cont {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
    width: 100%;
    height: 0px;
    overflow: hidden;
    transition: height .1s linear;
}

.video-statistics-cont.view {
    height: 550px;
    padding-top: 15px;
}

button.video-statistics-view {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    cursor: pointer;
    gap: 5px;
    width: 150px;
}

.graph-icon {
    font-size: 17px;
}

.video-statistic-graph {
    width: 100%;
}

.video-statistic-graph iframe {
    width: 100%;
    height: 225px;
}

.video-total-views {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 3px;
}

.blank-page-metadata {
    position: absolute;
    top: 60px;
    width: 100%;
    height: calc(100% - 60px);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #101525;
}

.blank-page-metadata .shape-loading {
    margin-top: 0;
}

.blank-page-metadata .loading-middle-box {
    height: 40px;
    width: 35px;
}

.blank-page-metadata .loading-left-box {
    height: 40px;
    width: 35px;
}

.blank-page-metadata .loading-right-box {
    height: 40px;
    width: 35px;
}

/* HOVER */
.video-statistics-view:hover {
    color: var(--dark-yellow);
}