.video-player-wrapper {
    width: 100%;
    position: relative;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
    z-index: 0;
}
.video-player-wrapper:not(.fullscreen){
    max-width: calc(178vh - 500px);
}
.video-fixed-aspect-ratio {
    width: 100%;
    height: 0;
    padding-top: 56.25%;
    position: relative;
}
.video-overlay, .video-player {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}
.video-overlay {
    z-index: 0;
    cursor: pointer;
    color: rgba(200, 200, 200, 0.75);
    font-size: 5vw;
    padding-bottom: 5px;
}
.video-controls {
    position: relative;
    display: flex;
    flex: auto;
    width: 100%;
    justify-content: space-between;
    margin-top: 5px;
    margin-bottom: 10px;
    cursor: auto;
}
/* .video-controls > * {
    display: flex;
    align-items: center;
    justify-content: center;
}
.video-controls-center {
    min-width: 140px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.video-controls > *:first-child, .video-controls >*:last-child {
    min-width: 25%;
}
.video-controls-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.video-controls-right .icon-button.fullscreen {
    font-size: 20px;
    margin-left: 7px;
} */

.video-controls-btn {
    margin: 0 auto;
    display: flex;
    align-items: center;
    gap: 10px;
}

.video-player-play-btn {
    position: absolute;
    top: 0;
    bottom: 30px;
    left: 0;
    right: 0;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 3px;
    margin: auto;
    cursor: pointer;
    font-size: 40px;
    border-radius: 50%;
    border: 1px solid white;
    color:white;
    background-color: rgba(150, 150, 150, 0.3);
}

.video-timecode {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    display: flex;
    padding-top: 3px;
    user-select: none;
    justify-content: flex-start;
    color: var(--dark-yellow);
}
.video-progress {
    z-index: 2;
    width: 100%;
    position: absolute;
    bottom: 55px;
    left: 0;
    height: 4px;
    background: rgba(22,31,53,.5);
    cursor: pointer;
}
.video-progress:before {
    content: "";
    position: absolute;
    top: -12px;
    height: 12px;
    width: 100%;
}
.video-overlay:hover .video-progress {
    height: 8px;
}
.video-buffer-progress, .video-playback-progress {
    pointer-events: none;
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    transition: width 0.1s linear;
}
.video-playback-progress:before {
    content: "";
    position: absolute;
    height: 8px;
    width: 8px;
    background: white;
    border-radius: 50%;
    top: -2px;
    right: -4px;
}
.video-position-marker {
    display: none;
    pointer-events: none;
    position: absolute;
    bottom: 14px;
    background: #161f35;
    background: rgba(17, 23, 40, 0.8);
    padding: 4px;
    color: white;
    font-size: 11px;
    text-shadow: #000 0 0 1px, #000 0 0 1px, #000 0 0 1px, #000 0 0 1px;
    z-index: 10;
    transform: translateX(-50%);
    transition: left 0.1s linear;
    border-radius: 4px;
}

.video-play-btn,
.video-pause-btn {
    display: none;
    font-size: 40px;
    cursor: pointer;
}

.video-controls .icon-button {
    font-size: 35px;
    cursor: pointer;
    color: rgba(255, 255, 255, 0.7);
}

.video-js .vjs-current-time,
.video-js:not(.vjs-liveui) .vjs-duration,
.video-js:not(.vjs-liveui) .vjs-current-time {
    display: block !important;
}

.video-js:not(.vjs-liveui) .vjs-time-divider {
    display: block !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    min-width: 0.2em !important;
}

.video-js .vjs-big-play-button,
.video-js .vjs-remaining-time,
.video-js .vjs-subs-caps-button,
.video-js .vjs-picture-in-picture-control,
.video-js .vjs-duration,
.video-js .vjs-time-divider {
    display: none !important;
}

.video-js.vjs-playing ~ .video-controls .video-pause-btn,
.video-js:not(.vjs-playing) ~ .video-controls .video-play-btn {
    display: block;
}

/* .video-js.vjs-layout-x-small .vjs-duration,
.video-js.vjs-layout-x-small .vjs-time-divider,
.video-js.vjs-layout-x-small .vjs-current-time {
    display: none;
} */

/* FULLSCREEN */
.video-player-wrapper.fullscreen .video-fixed-aspect-ratio {
    padding-top: unset;
    height: 100vh;
}
.video-player-wrapper.fullscreen .video-overlay {
    top: unset;
    position: absolute;
    bottom: 5%;
}
.video-player-wrapper.fullscreen .video-controls {
    position: absolute;
    bottom: 0;
}
.video-player-wrapper.fullscreen .video-controls {
    background: linear-gradient(180deg, transparent 0%, #050c27 100%);
    padding: 0 5px;
    padding-bottom: 7px;
}
.video-controls-right .icon-button.fullscreen {
    font-size: 20px;
    margin-left: 7px;
}

.video-wrap {
    position: relative;
    aspect-ratio: 16/9;
    cursor: pointer;
}

/* HOVER */
.video-progress:hover .video-position-marker {
    display: block;
}
.video-overlay:hover .video-playback-progress:before {
    height: 12px;
    width: 12px;
    top: -2px;
    right: -6px;
}
.video-playback-progress {
    background: white;
}
.video-buffer-progress {
    background: rgba(105, 160, 255, .6);
}
.video-volume-slider {
    width: 75px;
    opacity: 0.6;
}
.video-volume-slider:hover {
    opacity: 1;
}
.video-player-play-btn:hover {
    border-color: var(--dark-yellow);
    color: black;
    background-color: var(--dark-yellow);
}
.icon-button:hover {
    color: white;
}

/* MEDIA QUERY */
@media screen and (max-width: 768px) {
    .video-controls .icon-button {
        font-size: 32px;
    }
}