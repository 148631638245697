.stream-page {
    width: 100%;
    height: fit-content;
    padding-bottom: 15px;
}

.stream-key {
    display: flex;
    align-items: center;
    height: 100%;
    color: white
}

.stream-key p {
    width: 100%;
}

.copy-stream {
    display: flex;
    align-items: center;
    height: 75%;
    font-size: 1.2em;
    padding: 5px;
    color: white;
}

.copy-stream-info {
    position: relative;
    padding: 3px 0;
}

.copy-stream-title {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3px;
    opacity: 1;
    transition: opacity .2s linear;
}

.copy-stream-title svg {
    font-size: 15px;
}

.copy-stream-btn-cont {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 5px;
    margin: 7px;
    opacity: 0;
    transform: translateX(-100%);
    z-index: 0;
    transition: transform .1s linear, opacity .1s linear;
    pointer-events: none;
}

.stream-status {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    width: fit-content;
    padding: 0 5px;
    background-color: var(--button-green);
    font-weight: bold;
    visibility: hidden;
}

.stream-list-main {
    width: 100%;
}

.stream-list-container {
    width: 100%;
    min-height: 380px;
    height: fit-content;
    margin-bottom: 20px;
}

.stream-list-container.tall {
    height: 720px;
}

.stream-league {
    cursor: pointer;
    padding: 5px 10px;
    border: 1px solid grey;
    border-radius: 3px;
}

.stream-league.active {
    background-color: var(--dark-yellow);
    color: var(--dark-blue);
}

.live-circle-icon {
    height: 9px;
    width: 9px;
    border-radius: 50%;
    background-color: white;
    animation: blinking 3s linear infinite;
}

.stream-live-title {
    margin-bottom: 1px;
}

.stream-filter-cont {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    width: 100%;
    margin-top: 10px;
    border: 1px solid rgb(75, 75, 75);
}

.stream-filter-cont.extra-margin {
    margin: 25px 0;
}

.stream-filter-single {
    width: 33.33%;
    cursor: pointer;
    padding: 5px;
    border-right: 1px solid rgb(75, 75, 75);
    color: var(--grey);
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 600;
}

.stream-filter-single.active {
    background-color: var(--dark-yellow);
    color: var(--dark-blue);
}

.stream-filter-single:last-child {
    border-right: none;
}

.stream-filter-active {
    height: 30px;
    width: 33.33%;
    border-radius: 5px;
    background-color: var(--dark-yellow);
}

@keyframes blinking {
    50% {
        opacity: 0;
    }
    70% {
        opacity: 1;
    }
}

.stream-list-title-cont {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    background-color: rgba(0,0,0,.35);
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    padding: 10px 0;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 600;
    color: var(--grey);
}

.stream-list-data {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.stream-data {
    position: relative;
    width: 100%;
    height: 75px;
    display: flex;
    align-items: center;
    background-color: rgba(0,0,0,.35);
    cursor: pointer;
    border-radius: 3px;
}

.stream-data.info-active {
    background-color: rgb(1, 55, 105);
    z-index: 10;
}

.stream-data:not(.info-active).unused-live {
    background-color: rgb(60, 10, 10);
}

.stream-data:not(.info-active).is-unlisted {
    background-color: #2e3140;
}

.stream-team-logo-bg {
    position: absolute;
    top: 5px;
    bottom: 5px;
    left: 5px;
    margin: auto 0;
    overflow: hidden;
}

.stream-team-logo-bg img {
    height: 95px;
    transform: translateX(-45%) translateY(-15%) rotate(20deg);
    filter: brightness(30%);
}

.stream-desc {
    width: 25%;
}
.stream-broadcast {
    width: 20%;
}
.stream-valid-to {
    width: 20%;
}
.stream-user {
    width: 20%;
    padding: 0 3px;
    overflow-wrap: break-word;
}
.stream-copy-info {
    width: 15%;
}

.stream-desc.data {
    padding: 0 7px;
    font-weight: 600;
    z-index: 10;
}

.stream-desc.data,
.stream-broadcast.data,
.stream-valid-to.data,
.stream-user.data,
.stream-valid-to.data .sd-duration {
    font-size: 12px;
}

.broadcast-start-and-live {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 7px;
}

.stream-live {
    visibility: visible;
}

.ingest-period {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 7px;
}

.no-streams {
    text-align: middle;
    margin-top: 50px;
    color: rgba(255, 255, 255, 0.5);
}

.refresh-stream {
    display: flex;
    align-items: center;
    width: fit-content;
    gap: 2px;
    font-size: 13px;
    color: white;
    cursor: pointer;
    height: 42px;
    margin-left: 2px;
    justify-content: right;
}

.refresh-stream div {
    margin-left: 3px;
}

.data-info-head-title .refresh-stream {
    height: fit-content;
    margin: 0;
}

.refresh-stream-icon {
    width: 20px;
    height: 20px;
}

.refresh-icon {
    font-size: 20px;
}

.stream-refreshed-check {
    font-size: 15px;
    color: var(--button-green);
    margin-top: 2px;
}

.live-stream {
    color: var(--dark-yellow);
}
.expired-stream {
    color: rgba(255, 255, 255, 0.5);
}

.date-active {
    color: var(--button-green);
    font-weight: bold;
}
.date-scheduled {
    color: white;
}
.date-expired {
    color: var(--warning-text-red);
    font-weight: bold;
}

.copy-stream-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px;
    font-size: 11px;
    padding: 3px 0;
}

.copy-stream-btn svg {
    font-size: 15px;
}

.no-stream-selected-icon {
    font-size: 3em;
}

.stream-not-public {
    margin-top: 8px;
}

.schedule-new-stream {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 12px;
    margin-right: 10px;
}

.live-banner {
    width: fit-content;
    padding: 2px 5px;
    font-weight: 600;
    border-radius: 2px;
    border: 2px solid rgb(250, 50, 0);
    color: rgb(250, 50, 0);
}

/* HOVER */
.stream-data:hover,
.stream-data.unused-live:hover,
.stream-data.is-unlisted:hover {
    background-color: var(--hover-blue);
}
.copy-stream:hover,
.refresh-stream:hover,
.copy-stream-btn:hover {
    color: var(--dark-yellow);
}
.copy-stream-info:hover .copy-stream-title {
    opacity: 0;
}
.copy-stream-info:hover .copy-stream-btn-cont {
    opacity: 1;
    transform: translateX(0%);
    pointer-events: visible;
}
.stream-filter-single:not(.active):hover {
    color: white;
}

/* MEDIA QUERY */
@media screen and (max-width: 768px) {
    .schedule-new-stream button {
        font-size: 12px;
    }
    .stream-filter-single {
        font-size: 10px;
        padding: 10px 5px;
    }
    .stream-filter-single.active {
        font-weight: 800;
    }
    .live-banner {
        font-size: 11px;
    }
}

@media screen and (max-width: 480px) {
    .schedule-new-stream button {
        font-size: 11px;
    }
    .stream-filter-cont {
        margin-bottom: 0px;
    }
    .stream-filter-single {
        font-size: 9px;
        padding: 10px 3px;
    }
    .live-banner {
        margin: 2px 3px;
        font-size: 10px;
    }
    .schedule-new-stream {
        top: -8px;
    }
    .schedule-new-stream button {
        font-size: 13px;
    }
    .refresh-stream {
        height: 25px;
        margin: 7px 0;
    }
}