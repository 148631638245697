.profile-cont {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.logged-in-as {
    border-bottom: 1px solid white;
    padding-bottom: 7px;
    margin-bottom: 12px;
    font-size: 1em;
    text-transform: uppercase;
    font-weight: bold;
}

.profile-info-cont {
    width: 100%;
    max-width: 1440px;
    padding: 25px;
    background-color: #101525;
}

.profile-pic-and-name {
    display: flex;
    margin-bottom: 21px;
}

.profile-pic-cont {
    display: flex;
    align-items: center;
    height: 140px;
    width: 125px;
    padding: 24px;
    margin-right: 24px;
    border: 1px solid rgba(255, 255, 255, 0.25);
}

.profile-details-cont {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 10px;
    width: 60%;
}

.profile-details-cont button {
    width: 175px;
}

.pa-cont {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.pa-single {
    display: flex;
    align-items: flex-end;
    margin-right: 25px;
    width: 25%;
}

.pa-icon {
    font-size: 40px;
    margin-right: 7px;
    margin-bottom: -5px;
    color: var(--dark-yellow);
}

.pa-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 3px;
    width: 100%;
}

.pa-info-title {
    text-transform: uppercase;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.5);
}

.pa-info-groups {
    width: 100%;
}

.pa-info-groups div {
    float: left;
    margin-right: 5px;
}

.user-icon {
    font-size: 95px;
}

.user-name {
    flex-grow: 1;
    text-align: left;
    font-size: 2em;
}

.profile-build-version-text {
    color: grey;
    font-style: italic;
    font-size: small;
    margin-top: 12px;
}

/* CHANGE PASSWORD */
.change-password-cont {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 275px;
    margin-top: 7px;
    margin-bottom: 7px;
}

.change-password-title {
    width: 100%;
    text-align: center;
    font-size: 17px;
    margin-bottom: 25px;
}

.change-password-warning {
    min-height: 27px;
    padding: 3px 0;
    margin-bottom: 3px;
}

@media screen and (max-width: 1440px) {
    .profile-cont {
        align-items: center;
    }
}

@media screen and (max-width: 768px) {
    .profile-info-cont {
        padding: 20px 12px;
    }
    .pa-cont {
        width: 100%;
        display: unset;
        justify-content: unset;
    }
    .pa-single {
        width: 250px;
        float: left;
        display: flex;
        align-items: center;
        margin-right: 0;
        margin-bottom: 25px;
    }
    .pa-icon {
        font-size: 30px;
        color: var(--dark-yellow);
    }
    .pa-info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 2px;
        font-size: 13px;
    }
    .profile-pic-cont {
        padding: 15px;
        margin-right: 10px;
    }
    .user-name {
        width: 100%;
        font-size: 20px;
        overflow: hidden;
        word-break: break-all;
    }
}

@media screen and (max-width: 480px) {
    .profile-details-cont button {
        width: 100%;
    }
    .profile-pic-cont {
        height: 145px;
        width: 120px;
    }
    .user-name {
        font-size: 18px;
    }
}