.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 0 1 auto;
    background: var(--dark-blue);
    height: var(--header-size);
    border-bottom: 2px solid var(--dark-yellow);
}

.header-league-cont {
    display: flex;
    height: 100%;
}

.navigation {
    display: inline-flex;
    flex: 1 1;
    align-items: center;
    flex-flow: row wrap;
    justify-content: flex-end;
    text-transform: uppercase;
    font-weight: 700;
    margin: 0;
}
.navigation li > *:hover {
    color: var(--dark-yellow);
    filter: unset;
}
.header-user-icon {
    margin-bottom: -2px;
}
.header-blinking-update {
    font-weight: bold;
    animation: blink .5s infinite;
}

.compilation-header {
    max-width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0 9px;
    margin-top: -1px;
    padding: 12px;
    cursor: pointer;
}

.compilation-active {
    color: var(--dark-yellow);
}

.ch-icon {
    font-size: 15px;
    margin-right: 5px;
    margin-bottom: -2px;
}

@keyframes blink {
    0%, 49% {
        color: white;
    }
    50%, 100% {
        color: var(--dark-yellow);
    }
}

.header-logo {
    display: flex;
    align-items: center;
    height: 100%;
    width: auto;
    margin: 0 12px;
    font-size: 1em;
    color: white;
    text-decoration: none;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
}

.league-header-logo {
    height: 70%;
    width: auto;
}

.header-league {
    margin-left: 5px;
}

.header-active {
    color: var(--dark-yellow);
}

.nav-icon-main {
    display: none;
    margin-right: 12px;
    font-size: 32px;
    transition: transform .1s linear;
}

.nav-icon-main.active {
    transform: rotate(90deg);
}

.mobile-nav {
    position: absolute;
    top: 45px;
    left: 0;
    width: 100%;
    overflow: hidden;
    z-index: 20;
    border-bottom: .5px solid var(--dark-yellow);
    display: none;
}

.non-link-header {
    padding: 1em;
    margin-top: -1px;
    cursor: pointer;
}

.non-link-header.active {
    color: var(--dark-yellow);
}

.admin-navigation-cont {
    position: relative;
    height: 40px;
    margin-left: 10px;
    /* border: 1px solid red; */
}

.admin-management {
    display: flex;
    align-items: center;
    gap: 1px;
    height: 100%;
    cursor: pointer;
}

.admin-management svg {
    margin-top: 1px;
    font-size: 20px;
}

.admin-navigation-list,
.transparent-space {
    position: absolute;
    top: 45px;
    left: 0;
    height: 0px;
    width: 170px;
    background-color: #101525;
    z-index: 10;
    transform: translateX(-20%);
    transition: height .2s linear;
    overflow: hidden;
    cursor: pointer;
}

.transparent-space {
    top: 40px;
    height: 5px;
    background-color: transparent;
}

.show-list .admin-navigation-list {
    height: 160px;
}

.show-list .admin-management {
    color: var(--dark-yellow);
}

.show-list .admin-management svg {
    transform: rotate(90deg);
    transition: transform .1s linear;
}

/* HOVER */
.header-logo:hover,
.nav-icon-main:hover {
    color: var(--dark-yellow);
}

.compilation-header:hover,
.non-link-header:hover {
    color: var(--dark-yellow);
}

/* Media Queries */
@media screen and (max-width: 960px) {
    .nav-menu {
        display: none;
    }
    .mobile-nav {
        display: block;
        transition: all .15s linear;
        background: var(--dark-blue);
    }
    .nav-icon-main {
        display: flex;
        cursor: pointer;
    }
    .navigation {
        flex: 0;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        background: rgba(0,0,0,0.15);
        transition: all .15s linear;
    }
    .compilation-header:hover,
    .non-link-header:hover,
    .navigation li > *:hover {
        background-color: unset;
        color: var(--dark-yellow);
    }
}

@media screen and (max-width: 480px) {
    .header-league {
        display: none;
    }
    .header-logo {
        margin: 0;
        margin-left: 12px;
    }
}