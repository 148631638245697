.login-page {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 75px;
}

.login-container {
    display: flex;
    align-items: center;
    width: fit-content;
    gap: 50px;
    padding: 30px;
    background-color: #101525;
    border-radius: 2px;
}

.login-container .error-msg {
    flex-direction: column;
    font-weight: bold;
    font-style: italic;
    color: red;
    text-align: center;
    min-height: 1em;
    width: fit-content;
}
.login-container form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.login-container button {
    width: 300px;
}

.login-build-version-text {
    color: grey;
    font-style: italic;
    font-size: small;
    margin-top: 20px;
}

.login-welcome {
    font-size: 27px;
    font-weight: 600;
}

.login-fotbollkonto-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 15px;
}

.login-input {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 7px 0;
    background-color: rgba(255, 255, 255, 0.1);
    margin-bottom: 12px;
    border-radius: 2px;
}

.login-input label {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 9px;
    margin-bottom: 1px;
    font-size: 20px;
    color: rgba(255, 255, 255, 0.8);
}

.login-input input,
.login-input input[type="password"] {
    background-color: unset;
    border: none;
    border-left: 1px solid rgba(255, 255, 255, 0.8);
    height: 28px;
    padding-left: 10px;
    outline: none;
    border-radius: 0;
}

.login-input input::placeholder {
    color: rgba(255, 255, 255, 0.5);
}

.login-with-username {
    margin-bottom: 15px;
    color: rgba(255, 255, 255, 0.7);
}

.login-logo-and-name {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.login-association-logo {
    height: 200px;
    max-width: 100%;
    margin: 0 auto;
    margin-bottom: 10px;
}

.login-association-name {
    font-size: 22px;
    font-weight: 600;
}

.mam-long-name {
    font-size: 17px;
    color: rgba(255, 255, 255, 0.5);
    margin-top: 2px;
    margin-bottom: 32px;
}

.login-or {
    font-size: 14px;
    font-weight: bold;
}

.error-msg.login {
    height: 25px;
}

@media screen and (max-width: 768px) {
    .login-container {
        flex-direction: column;
        gap: 35px;
        padding: 30px 15px;
    }
    .login-association-logo {
        height: 150px;
        margin-bottom: 0;
    }
    .login-association-name {
        font-size: 18px;
    }
    .login-page {
        padding-bottom: 0;
    }
    .mam-long-name {
        margin-bottom: 25px;
    }
    .login-input input,
    .login-input input[type="password"],
    .login-input input::placeholder {
        font-size: 14px;
    }
}